import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AppService } from '@Services/app.service';
import { SearchService } from '@Shared/components/search/search-extras/search.service';
import { UserService } from '@Services/user.service';
import { MenuItem } from '@Shared/models/IMenuItem';
import { IUserMenuConfigDetail } from '@User/usermenu/usermenu-extras/usermenu.interface';
import { UtilityService } from '@Services/utility.service'; 
import { UsermenuService } from '@User/usermenu/usermenu-extras/usermenu.service';
import { PermissionManager } from 'projects/evolutics-shared-lib/src/lib/functions/permission-manager.class';

@Injectable({
  providedIn: 'root',
})
export class IsAllowedGuard {
  createEndPaths = [
    '/edit',
    '/create',
    '/create/index',
    '/clone',
    '/client-desk/create/individual',
    '/client-desk/create/corporate',
    '/client-desk/create/provider',
  ];
  viewEndPaths = ['/show', '/client-desk/view-client'];
  url: string;
  userMenuItem: IUserMenuConfigDetail;

  constructor(
    public appS: AppService,
    public router: Router,
    // public userS: UserService,
    public userMS: UsermenuService,
    public searchS: SearchService,
    public uS: UtilityService,
  ) {
    this.createEndPaths.forEach((x) => (x = x.toLowerCase()));
    this.viewEndPaths.forEach((x) => (x = x.toLowerCase()));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard(route, state);
  }

  private _guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const allowed = this.__guard(route, state);
    if (allowed) return true;
    // debugger;
    // console.log(
    //   'userMenuItem',
    //   this.url,
    //   this.userMenuItem,
    //   environment?.userMenu?.userMenuConfigDetails
    // );
    this.router.navigate(['/no-access']);
    return false;
  }

  private __guard(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
    if (route.routeConfig?.customData?.miID)
      PermissionManager.routeMIDs.push(route.routeConfig?.customData?.miID);
    if (environment.noUserRoleCheck || route.firstChild) return true;
    // debugger;
    this.url = state.url.split('?')[0]?.toLowerCase();
    const isCreate = this.createEndPaths.some((x) => this.url.endsWith(x));
    const isView = this.viewEndPaths.some((x) => this.url.endsWith(x));

    // menuItem = PermissionManager.findClosetParentByURL(
    //   this.url,
    //   this.userMS.getAllMenuItemsToLocal(),
    //   this.userMS.routeMID
    // );
    PermissionManager.lastRouteMID = PermissionManager.routeMIDs?.pop();
    PermissionManager.lastParentRouteMID = PermissionManager.routeMIDs?.pop();
    if (!PermissionManager.lastParentRouteMID || !PermissionManager.lastRouteMID) {
      const urlMenuID =
        PermissionManager.menuLinkToIDIndex[this.url?.toLowerCase()] ||
        PermissionManager.findClosetParentByURL(this.url, this.searchS.menuIndex)?.id;
      !PermissionManager.lastRouteMID
        ? (PermissionManager.lastRouteMID = urlMenuID)
        : (PermissionManager.lastParentRouteMID = urlMenuID);
    }
    const menuItem = PermissionManager.selectFromComplexMap(
      PermissionManager.lastRouteMID,
      PermissionManager.lastParentRouteMID,
      this.appS.system as any,
    );
    // debugger;

    console.log('menuItem found'.toUpperCase(), menuItem);

    PermissionManager.pagePermission = { ...menuItem, slug: PermissionManager.lastRouteMID };

    if (!menuItem && !isCreate && !isView) return true;
    if (!menuItem?.viewAccess) return false;
    if (menuItem?.editAccess) return true;
    if (isCreate) return false;
    if (isView && menuItem?.viewAccess) return true;
    return true;
  }
}
